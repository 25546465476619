export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: "Kalob.NET - Blog",

  author: "Kalob Porter",
  description: "Software & stuff",

  // The number of posts to a page on the site index.
  indexPageSize: 10,
}
